import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import Container from '../components/Container';
import Text from '../components/Text';
import config from '../config';

const SERVICES_LIST = [
  'Lymphatic Drainage',
  'Pregnancy',
  'Deep Tissue',
  'Relaxation',
  'Compression Syndromes',
  'TMJ',
  'Headaches & Migraines',
];

const PRICING_LIST = [
  '30 minutes - $65 + HST',
  '45 minutes - $80 + HST',
  '60 minutes - $100 + HST',
  '75 minutes - $115 + HST',
  '90 minutes - $135 + HST',
  '60 minute Hot Stone - $120 + HST',
  '75 minute Hot Stone - $135 + HST',
  '90 minute Hot Stone - $160 + HST',
];

const Content = styled.div`
  height: 100%;
  width: 100%;

  > h1 {
    margin-bottom: ${config.sizes.spacing}px;
  }

  h2 {
    margin-bottom: ${config.sizes.spacing / 2}px;
    margin-top: ${config.sizes.spacing / 2}px;
  }

  > p {
    margin-bottom: ${config.sizes.spacing * 2}px;
  }
`;

const CleanList = styled.ul`
  list-style: circle outside;

  a {
    text-decoration: none;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  span {
    text-transform: none;
  }

  li {
    margin-left: ${config.sizes.spacing}px;
  }
`;

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Therapeutic Kneads | Services</title>
      </Helmet>
      <Container>
        <Content>
          <Text as="h1" size="x-large" weight="bold">
            Services
          </Text>
          <Text as="h2" size="large" weight="bold">
            {'Treatments we offer'}
          </Text>
          <CleanList>
            {SERVICES_LIST.map(serviceName => (
              <li key={serviceName}>
                <Text as="span" size="medium" color={config.colors.alt}>
                  {serviceName}
                </Text>
              </li>
            ))}
          </CleanList>
          <Text as="h2" size="large" weight="bold">
            {'Pricing'}
          </Text>
          <CleanList>
            {PRICING_LIST.map(serviceName => (
              <li key={serviceName}>
                <Text as="span" size="medium" color={config.colors.alt}>
                  {serviceName}
                </Text>
              </li>
            ))}
          </CleanList>
        </Content>
      </Container>
    </>
  );
};

export default Services;
